import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import { AddNewLinkComponent } from './add-new-link.component';

@NgModule({
  declarations: [AddNewLinkComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class AddNewLinkModule {
}