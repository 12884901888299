import { identifierModuleUrl } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddNewPointsComponent } from '../add-new-points/add-new-points.component';
import { DatabaseService } from '../database.service';
import { Member } from '../model/member';

@Component({
  selector: 'app-members',
  templateUrl: './members.page.html',
  styleUrls: ['./members.page.scss'],
})
export class MembersPage implements OnInit {

  members: Member[] = []

  constructor(public databaseService: DatabaseService,
    private modalController: ModalController) {
  }

  ngOnInit() {
    this.members = this.databaseService.members
  }

  addNewPoints(){
    this.modalController.create({
      component: AddNewPointsComponent
    }).then(modal => modal.present());
  }

  getLevelOfMember(upn: string){
    let points = this.databaseService.getPointsOfMember(upn);
    return this.databaseService.getLevel(points).name;
  }
}
