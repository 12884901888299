import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatabaseService } from '../database.service';
import { Link } from '../model/link';

@Component({
  selector: 'app-add-new-link',
  templateUrl: './add-new-link.component.html',
  styleUrls: ['./add-new-link.component.scss'],
})
export class AddNewLinkComponent implements OnInit { 

  name: string;
  link: string;

  constructor(private modalController: ModalController,
    public databaseService: DatabaseService) { }

  ngOnInit() {}

  closePopup() {
    this.modalController.dismiss();
  }

  saveNewLink() {
    this.databaseService.addNewLink({
      name: this.name,
      link: this.link
    } as Link)
      .then(() => this.modalController.dismiss());
  }

}
