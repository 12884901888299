import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatabaseService } from '../database.service';

@Component({
  selector: 'app-add-new-points',
  templateUrl: './add-new-points.component.html',
  styleUrls: ['./add-new-points.component.scss'],
})
export class AddNewPointsComponent implements OnInit {

  points: number = 0;
  memberUPN: string[];
  type: string

  constructor(private modalController: ModalController,
    public databaseService: DatabaseService) { }

  ngOnInit() {}

  closePopup() {
    this.modalController.dismiss();
  }

  saveNewPoints() {
    this.memberUPN.forEach(item => {
      this.databaseService.saveNewPoints(item, this.type)
      .then(() => this.modalController.dismiss());
    })
  }

}
