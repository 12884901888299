import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import { AddNewPointsComponent } from './add-new-points.component';

@NgModule({
  declarations: [AddNewPointsComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class AddNewPointsModule {
}