import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { MenuController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {

  constructor(public authService: AuthService,
    public menuCtrl: MenuController,
    public router: Router) {
      if(!authService.isLoggedIn){
        authService.MSLogin();
      }else{
        this.router.navigate(['members']);
      }
     }

  ngOnInit() {}

  ionViewWillEnter() {
    this.menuCtrl.enable(false);
  }

}
