import {
    Component, OnInit
}
from '@angular/core';
import {
    DatabaseService
}
from '../database.service';
import {
    ModalController
}
from '@ionic/angular';

@Component({ selector: 'app-add-new-event', templateUrl: './add-new-event.component.html', styleUrls: ['./add-new-event.component.scss'], }) export class AddNewEventComponent implements OnInit {
    title: string;
    topic: string;
    location: string;
    start: Date;
    end: Date;
    contact: string;
    type: string;
    link: string;
    saveNewEvent() {
        this.databaseService.saveNewEvent(this.title, this.topic, this.location, this.link, new Date(this.start), new Date(this.end), this.contact, this.type).then(() => this.modalController.dismiss());
    }
    closePopup() {
        throw new Error('Method not implemented.');
    }

    constructor(private modalController: ModalController, public databaseService: DatabaseService) {}

    ngOnInit() {}

}
