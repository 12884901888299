// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'cf-c3-community-aw',
    appId: '1:1053986685981:web:2139c43f5ee50fab4c32f4',
    storageBucket: 'cf-c3-community-aw.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyBC60fORJ_lpcLdDF4YCeUHVLyMlAV0dH0',
    authDomain: 'cf-c3-community-aw.firebaseapp.com',
    messagingSenderId: '1053986685981',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
