import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Level } from '../model/level';
import { RankingMember } from '../model/rankingMember';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.page.html',
  styleUrls: ['./ranking.page.scss'],
})
export class RankingPage implements OnInit {

  constructor(public databaseService: DatabaseService) { }

  ngOnInit() {
  }

  getMembersofLevel(level: Level): RankingMember[] {
    let array = [];
    this.databaseService.members.forEach(member => {
      if(this.getLevelOfMember(member.upn) == level.name){
        array.push({
          displayName: member.displayName,
          upn: member.upn,
          points: this.databaseService.getPointsOfMember(member.upn)
        } as RankingMember);
      }
    });
    array.sort((a,b) => b.points - a.points);
    return array;
  }

  getLevelOfMember(upn: string){
    let points = this.databaseService.getPointsOfMember(upn);
    return this.databaseService.getLevel(points).name;
  }

  getLevels(){
    return this.databaseService.levels.sort((a,b) => a.points - b.points);
  }

}
