import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard'
import { SignInComponent } from './sign-in/sign-in.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'members',
    loadChildren: () => import('./members/members.module').then( m => m.MembersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ranking',
    loadChildren: () => import('./ranking/ranking.module').then( m => m.RankingPageModule),
    canActivate: [AuthGuard]
  },
  { path: 'sign-in', 
    component: SignInComponent 
  },
  {
    path: 'work-items',
    loadChildren: () => import('./work-items/work-items.module').then( m => m.WorkItemsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'quick-links',
    loadChildren: () => import('./quick-links/quick-links.module').then( m => m.QuickLinksPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'feature-requests',
    loadChildren: () => import('./feature-requests/feature-requests.module').then( m => m.FeatureRequestsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },  {
    path: 'rules',
    loadChildren: () => import('./rules/rules.module').then( m => m.RulesPageModule)
  },
  {
    path: 'modify-rule',
    loadChildren: () => import('./modify-rule/modify-rule.module').then( m => m.ModifyRulePageModule)
  },
  {
    path: 'cloud-feeds',
    loadChildren: () => import('./cloud-feeds/cloud-feeds.module').then( m => m.CloudFeedsPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'modify-event',
    loadChildren: () => import('./modify-event/modify-event.module').then( m => m.ModifyEventPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
