import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Dashboard', url: 'dashboard', icon: 'speedometer' },
    { title: 'Members', url: 'members', icon: 'people' },
    { title: 'Work Items', url: 'work-items', icon: 'checkbox'},
    { title: 'CloudFeeds', url: 'cloud-feeds', icon: 'book'},
    { title: 'QuickLinks', url: 'quick-links', icon: 'link'},
    { title: 'Ranking', url: 'ranking', icon: 'podium' },
    { title: 'Feature Requests', url: 'feature-requests', icon: 'chatbox' },
    { title: 'Rules', url: 'rules', icon: 'bookmarks' },
    { title: 'Events', url: 'events', icon: 'calendar' }
  ];

  constructor() {}
}
