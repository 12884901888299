import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatabaseService } from '../database.service';

@Component({
  selector: 'app-add-new-rule',
  templateUrl: './add-new-rule.component.html',
  styleUrls: ['./add-new-rule.component.scss'],
})
export class AddNewRuleComponent implements OnInit {
  type: string = "level";
  points: number;
  name: string;
  short: string;
  
  constructor(private modalController: ModalController,
    public databaseService: DatabaseService) { }

  ngOnInit() {}

  closePopup() {
    this.modalController.dismiss();
  }

  saveNewRule() {
    if(this.type == "level"){
      this.databaseService.saveNewLevel(this.name, this.points).then(() => this.modalController.dismiss());;
    }else{
      this.databaseService.saveNewCategory(this.name, this.points, this.short).then(() => this.modalController.dismiss());;
    }
  }

}
