import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatabaseService } from '../database.service';
import { FeatureRequest } from '../model/featureRequest';

@Component({
  selector: 'app-add-new-feature-request',
  templateUrl: './add-new-feature-request.component.html',
  styleUrls: ['./add-new-feature-request.component.scss'],
})
export class AddNewFeatureRequestComponent implements OnInit {
  
  name: string;
  shortDescription: string;
  type: string;

  constructor(private modalController: ModalController,
    private databaseService: DatabaseService) { }

  ngOnInit() {}

  closePopup() {
    this.modalController.dismiss();
  }

  saveNewFR() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.databaseService.addNewFR({
      name: this.name,
      type: this.type,
      shortDescription: this.shortDescription,
      created: new Date(),
      state: 'open',
      stateChanged: new Date(),
      voters: [user.email],
      createdBy: user.email
    } as FeatureRequest)
      .then(() => this.modalController.dismiss());
  }
}
