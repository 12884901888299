import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

import { AddNewFeatureRequestComponent } from './add-new-feature-request.component';

@NgModule({
  declarations: [AddNewFeatureRequestComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
})
export class AddNewFeatureRequestModule {
}