import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { User } from './model/user';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { OAuthProvider } from 'firebase/auth';
import { getAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  whitelist = ["p.korte@rheinenergie.com","e.schubert@rheinenergie.com"]

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,  
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {    
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
        this.SetUserData(JSON.parse(localStorage.getItem('user')));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && this.whitelist.includes(user.email)) ? true : false;
  }

  // Auth logic to run auth providers
  MSLogin() { 
    getAuth().tenantId = 'admins-szylj';
    let provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: "17e9d4a2-1c8d-40d2-af3f-929da470e0c7"
    });
    this.afAuth.signInWithRedirect(provider);
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`admins/${user.email}`);
    const userData: User = {
      upn: user.email,
      displayName: user.displayName
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  // Sign out 
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    })
  }

}