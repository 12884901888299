import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore, } from '@angular/fire/firestore';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { FormsModule } from '@angular/forms';
import { AddNewPointsModule } from './add-new-points/add-new-points-module';
import { DatabaseService } from './database.service';
import { MembersPageModule } from './members/members.module';
import { RankingPageModule } from './ranking/ranking.module';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { AuthService } from './auth.service';
import { AddNewLinkModule } from './add-new-link/add-new-link-module';
import { AddNewFeatureRequestModule } from './add-new-feature-request/add-new-feature-request-module';
import { AddNewRuleModule } from './add-new-rule/add-new-rule-module';
import { AddNewFeedModule } from './add-new-feed/add-new-feed-module';
import { HttpClientModule} from '@angular/common/http';
import { AddNewEventModule } from './add-new-event/add-new-event-module';

@NgModule({
  declarations: [AppComponent], 
  entryComponents: [],
  imports: [BrowserModule,
    FormsModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    provideFirebaseApp(() => initializeApp(environment.firebase)), 
    provideAuth(() => getAuth()), 
    provideFirestore(() => getFirestore()), 
    provideFunctions(() => getFunctions()),
    AddNewPointsModule,
    AddNewLinkModule,
    AddNewFeatureRequestModule,
    AddNewRuleModule,
    AddNewEventModule,
    AddNewFeedModule,
    MembersPageModule,
    RankingPageModule,
    HttpClientModule, 
    AngularFireModule.initializeApp(environment.firebase) ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatabaseService,
    AngularFirestore,
    AuthService],
  bootstrap: [AppComponent],
})
export class AppModule {}
