import { Injectable } from '@angular/core';
import { collection, collectionData, Firestore } from '@angular/fire/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Member } from './model/member';
import { Points } from './model/points';
import { WorkItem } from './model/workItem';
import { Link } from './model/link';
import { FeatureRequest } from './model/featureRequest';
import { Level } from './model/level';
import { Category } from './model/category';
import { Feed } from './model/feed';
import { Event } from './model/event';


@Injectable()
export class DatabaseService {

  members: Member[] = [];
  points: Points[] = [];
  workItems: WorkItem[] = [];
  quickLinks: Link[] = [];
  levels: Level[] = [];
  feeds: Feed[] = [];
  categories: Category[] = [];
  date = new Date(); 
  now = new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(), this.date.getUTCDate(), this.date.getUTCHours(), this.date.getUTCMinutes(), this.date.getUTCSeconds());
  featureRequests: FeatureRequest[] = [];
  events: Event[] = []

  private firestorePointsCollection = this.db.collection('points');
  private firestoreLevelCollection = this.db.collection('levels');
  private firestoreCategoryCollection = this.db.collection('categories');
  private firestoreEventsCollection = this.db.collection('events');

  constructor(private firestore: Firestore, private db: AngularFirestore) {  
    this.now.setFullYear(this.now.getFullYear()-1);

    this.db.collection('levels').snapshotChanges().subscribe(value => {
      this.levels = value.map(item => levelDocToLevel(item.payload.doc.data(), item.payload.doc.id));
      this.levels = this.levels.sort((a,b) => a.points - b.points);
    });

    collectionData(collection(firestore, 'members')).subscribe(value => {
      this.members = value.map(item => memberDocToMember(item))
    });
    
    collectionData(collection(firestore, 'points')).subscribe(value =>{
      this.points = value.map(item => pointDocToPoints(item));
    });

    this.db.collection('workItems').snapshotChanges().subscribe(value => {
      this.workItems = [];
      value.forEach(item => {
        this.workItems.push(workItemDocToWorkItem(item.payload.doc.data(), item.payload.doc.id))
      });
    });

    this.db.collection('management').doc('links').snapshotChanges().subscribe(value => {
      this.quickLinks = linkDocToLinkArray(value.payload.data());
    });

    this.db.collection('featureRequests').snapshotChanges().subscribe(value => {
      this.featureRequests = value.map(item => FRDocToFR(item.payload.doc.data(), item.payload.doc.id));
    });

    this.db.collection('categories').snapshotChanges().subscribe(value => {
      this.categories = value.map(item => categoryDocToCategory(item.payload.doc.data(), item.payload.doc.id));
    });

    this.db.collection('feeds').snapshotChanges().subscribe(value => {
      this.feeds = value.map(item => feedDocToFeed(item.payload.doc.data()));
    });

    this.db.collection('events').snapshotChanges().subscribe(value => {
      this.events = value.map(item => eventDocToEvent(item.payload.doc.data(), item.payload.doc.id));
    });
  }

  getLevel(points: number): Level{
    let tempLevels = this.levels.sort((a,b) => b.points - a.points);
    let index = 0;

    while (index < tempLevels.length){
      if(points >= tempLevels[index].points){
        return tempLevels[index];
      }
      index++;
    }
  }

  getEvents(): Event[]{
    console.log(this.events[0].start.valueOf())
    return this.events.sort((a,b) => a.start.valueOf() - b.start.valueOf()).filter(item => item.end > new Date);
  }

  getUnreadWorkItems(): WorkItem[] {
    let workItems = [];

    this.workItems.forEach(item => {
      if(!item.ack){
        workItems.push(item);
      }
    });
    
    return workItems;
  }

  getFeeds(): Feed[]{
    return this.feeds.sort((a, b) => b.id-a.id)
  }

  setWorkItemAsRead(id: string){
    this.db.collection('workItems').doc(id).set({
      ack: true
    }, {merge: true});
  }

  getPointsOfMember(upn: string): number {
    let memberPoints = 0;
    this.points.forEach(item => {
      if(item.memberUPN === upn && item.timestamp >= this.now){
        memberPoints += item.points;
      }
    })
    return memberPoints;
  }

  addNewLink(link: Link): Promise<any> {
    this.quickLinks.push(link);
    return new Promise((resolve, reject) => {
      this.db.collection('management').doc('links').set({
        quickLinks: this.quickLinks
      },{merge: true}).then(() => resolve(link));
    });
  }

  addNewFeed(feed: Feed): Promise<any> {
    this.feeds.push(feed);
    return new Promise((resolve, reject) => {
      this.db.collection('feeds').doc(feed.id.toString()).set({
        title: feed.title,
        text: feed.text,
        created: feed.created,
        id: feed.id
      }).then(() => resolve(feed));
    });
  }

  deleteLink(index: number){
    this.quickLinks.splice(index, 1);

    this.db.collection('management').doc('links').set({
      quickLinks: this.quickLinks
    },{merge: true})
  }

  deleteLevel(id: string){
    this.db.collection('levels').doc(id).delete()
  }

  deleteEvent(id: string) {
    this.db.collection('events').doc(id).delete()
  }

  deleteCategory(id: string){
    this.db.collection('categories').doc(id).delete().catch(e => console.log(e))
  }

  saveQuickLinksReorder(array: Link[]){
    this.db.collection('management').doc('links').set({
      quickLinks: array
    },{merge: true})
  }

  modifyLink(index: number, link: Link): Promise<any>{
    this.quickLinks[index] = link;

    return new Promise((resolve, reject) => {
      this.db.collection('management').doc('links').set({
      quickLinks: this.quickLinks
    },{merge: true}).then(() => resolve(link));
  });
  }

  saveNewPoints(memberUPN: string, type: string): Promise<number> {
    return new Promise((resolve, reject) => {
      var filtered = this.categories.filter(c => c.name == type);
      var date = new Date(); 
      var now_utc =  new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return this.firestorePointsCollection.doc().set({
        points: filtered[0].points,
        memberUPN: memberUPN,
        timestamp: now_utc,
        type: type
      } as Points).then(() => resolve(filtered[0].points));
    });
  }

  saveNewLevel(name: string, points: number): Promise<number> {
    return new Promise((resolve, reject) => {
      var date = new Date(); 
      var now_utc =  new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return this.firestoreLevelCollection.doc().set({
        points: points,
        name: name,
        created: now_utc,
      } as Level).then(() => resolve(points));
    });
  }

  saveNewCategory(name: string, points: number, shortDescription: string): Promise<number> {
    return new Promise((resolve, reject) => {
      var date = new Date(); 
      var now_utc =  new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return this.firestoreCategoryCollection.doc().set({
        points: points,
        name: name,
        created: now_utc,
        shortDescription: shortDescription
      } as Category).then(() => resolve(points));
    });
  }

  saveLevel(name: string, points: number, id: string): Promise<number> {
    return new Promise((resolve, reject) => {
      var date = new Date(); 
      var now_utc =  new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return this.firestoreLevelCollection.doc(id).set({
        points: points,
        name: name,
        created: now_utc,
      } as Level, {merge: true}).then(() => resolve(points));
    });
  }

  saveNewEvent(title, topic, location, link, start, end, contact, type) {
    return new Promise((resolve, reject) => {
      var date = new Date(); 
      var now_utc =  new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return this.firestoreEventsCollection.doc().set({
        title: title,
        topic: topic,
        location: location,
        link: link,
        start: start,
        end: end,
        contact: contact,
        type: type,
        created: now_utc,
      }, {merge: true}).then(() => resolve(now_utc));
    });
  }

  modifyEvent(event: Event) {
    return new Promise((resolve, reject) => {
      var date = new Date(); 
      var now_utc =  new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return this.firestoreEventsCollection.doc(event.id).set({
        title: event.title,
        topic: event.topic,
        location: event.location,
        link: event.link,
        start: event.start,
        end: event.end,
        contact: event.contact,
        type: event.type,
      }, {merge: true}).then(() => resolve(now_utc));
    });
  }

  saveCategory(name: string, points: number, shortDescription: string, id: string): Promise<number> {
    return new Promise((resolve, reject) => {
      var date = new Date(); 
      var now_utc =  new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
      return this.firestoreCategoryCollection.doc(id).set({
        points: points,
        name: name,
        created: now_utc,
        shortDescription: shortDescription
      } as Category, {merge: true}).then(() => resolve(points));
    });
  }

  // FeatureRequests
  getOpenFeatureRequests(): FeatureRequest[] {
    let array = [];
    if(this.featureRequests){
      this.featureRequests.forEach(element => {
        if(element.state != "canceled" && element.state != "success"){
          array.push(element);
        }
      });
    }
    array.sort((a,b) => b.voters.length - a.voters.length)
    return array;
  }

  FRVote(fr: FeatureRequest) {
    this.db.collection('featureRequests').doc(fr.id).set(fr, {merge: true});
  }

  addNewFR(fr: FeatureRequest): Promise<any>{
    return new Promise((resolve, reject) => {
     return this.db.collection('featureRequests').doc().set({
       created: fr.created,
       createdBy: fr.createdBy,
       name: fr.name,
       shortDescription: fr.shortDescription,
       state: fr.state,
       stateChanged: fr.stateChanged,
       type: fr.type,
       voters: fr.voters
     }).then(() => resolve('success'));
    });
  }

  changeFRState(fr: FeatureRequest, state: string) {
    this.db.collection('featureRequests').doc(fr.id).set({
      state: state,
      stateChanged: new Date()
    }, {merge:true});
  }
}
function FRDocToFR(item: any, docId: string): FeatureRequest {
  return {
    name: item.name,
    shortDescription: item.shortDescription,
    state: item.state,
    voters: item.voters,
    created: item.created.toDate(),
    stateChanged: item.stateChanged.toDate(),
    createdBy: item.createdBy,
    id: docId,
    type: item.type
} as FeatureRequest
}

function levelDocToLevel(item: any, id: string): Level {
  return {
    name: item.name,
    created: item.created.toDate(),
    points: item.points,
    id: id
} as Level
}

function categoryDocToCategory(item: any, id: string): Category {
  return {
    name: item.name,
    created: item.created.toDate(),
    points: item.points,
    shortDescription: item.shortDescription,
    id: id
} as Category
}

function feedDocToFeed(item: any): Feed {
  return {
    title: item.title,
    created: item.created.toDate(),
    text: item.text,
    id: item.id
} as Feed
}

function memberDocToMember(item: any): Member {
  return {
        displayName: item.displayName,
        upn: item.upn,
  } as Member
}

function pointDocToPoints(item: any): Points {
  return {
    points: item.points,
    timestamp: item.timestamp.toDate(),
    type: item.type,
    memberUPN: item.memberUPN,
  } as Points
}

function workItemDocToWorkItem(item: any, docId: string): WorkItem {
  return {
    type: item.type,
    points: item.points,
    level: item.level,
    levelBefore: item.levelBefore,
    ack: item.ack,
    timestamp: item.timestamp.toDate(),
    memberUPN: item.memberUPN,
    id: docId
    } as WorkItem
}

function linkDocToLinkArray(item: any): Link[] {
  let array = [];

  item.quickLinks.forEach(element => {
    array.push({
      link: element.link,
      name: element.name
    } as Link)
  });

  return array;
}

function eventDocToEvent(item: any, docID: string): any {
  return {
    title: item.title,
    topic: item.topic,
    location: item.location,
    link: item.link,
    start: item.start.toDate(),
    end: item.end.toDate(),
    created: item.created,
    id: docID, 
    contact: item.contact,
    type: item.type
  } as Event
}
