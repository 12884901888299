import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatabaseService } from '../database.service';
import { Feed } from '../model/feed';


@Component({
  selector: 'app-add-new-feed',
  templateUrl: './add-new-feed.component.html',
  styleUrls: ['./add-new-feed.component.scss'],
})
export class AddNewFeedComponent implements OnInit {

  title: string;
  text: string;
  number: Number;

  constructor(private modalController: ModalController,
    public databaseService: DatabaseService) { }

  ngOnInit() {}

  closePopup() {
    this.modalController.dismiss();
  }

  saveNewFeed() {
    this.databaseService.addNewFeed({
      title: this.title,
      text: this.text,
      created: new Date(),
      id: this.number
    } as Feed)
      .then(() => this.modalController.dismiss());
  }

}
